// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.naao-link {
  display: block;
  padding: $naao-link-padding-y $naao-link-padding-x;

  @include hover-focus {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $naao-link-disabled-color;
  }
}

//
// Tabs
//

.naao-tabs {
  border-bottom: $naao-tabs-border-width solid $naao-tabs-border-color;

  .naao-item {
    margin-bottom: -$naao-tabs-border-width;
  }

  .naao-link {
    border: $naao-tabs-border-width solid transparent;
    @include border-top-radius($naao-tabs-border-radius);

    @include hover-focus {
      border-color: $naao-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $naao-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .naao-link.active,
  .naao-item.show .naao-link {
    color: $naao-tabs-link-active-color;
    background-color: $naao-tabs-link-active-bg;
    border-color: $naao-tabs-link-active-border-color;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$naao-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}


//
// Pills
//

.naao-pills {
  .naao-link {
    @include border-radius($naao-pills-border-radius);
  }

  .naao-link.active,
  .show > .naao-link {
    color: $naao-pills-link-active-color;
    background-color: $naao-pills-link-active-bg;
  }
}


//
// Justified variants
//

.naao-fill {
  .naao-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.naao-justified {
  .naao-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
